import { useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { applicationKey, defaultPageParam } from './constants';
import applicationsApiHandler from 'infrastructure/apiHandlers/applications';
import { pageAndLimitDefaultValue } from 'common/constants/common';
import { IApplicationsApiResponseModel } from 'common/globalTypes/interfaces/apiResponseModel';

const useApplicationsInfiniteQuery = (
  initialApplicationsFromServerSide: IApplicationsApiResponseModel,
  categoryAlias: string
) => {
  const { data: applicationsFromCache, ...infiniteQueryProps } =
    useInfiniteQuery(
      [applicationKey, categoryAlias],
      ({ pageParam }) =>
        applicationsApiHandler.getApplications({
          page: pageParam,
          limit: pageAndLimitDefaultValue.limit
        }),
      {
        getNextPageParam: lastPage => {
          const {
            paginatorInfo: { hasMorePages, currentPage }
          } = lastPage;

          return hasMorePages ? currentPage + 1 : undefined;
        },
        initialData: () => ({
          pages: [initialApplicationsFromServerSide],
          pageParams: [defaultPageParam]
        }),
        enabled: false
      }
    );

  return {
    data: useMemo(
      () =>
        applicationsFromCache?.pages.flatMap(
          application => application.items
        ) || [],
      [applicationsFromCache?.pages]
    ),
    ...infiniteQueryProps
  };
};

export default useApplicationsInfiniteQuery;
