import { InputProps } from 'antd/es/input/index.d';

export enum SearchTypeEnum {
  main = 'main',
  default = 'default'
}

export interface ISearchInputProps extends InputProps {
  searchType?: keyof typeof SearchTypeEnum;
  loading: boolean;
}
