import styled from 'styled-components';
import Menu from 'antd/lib/menu';

import Heading from 'components/atoms/Heading';
import { sizes } from 'styles/variables.module';

export const FilterCategoryWrapper = styled(Menu)`
  &&& {
    border-inline-end: none;

    .ant-menu-item-group-title {
      padding: 0;
      margin-bottom: ${sizes.spm};
    }

    .ant-menu-item-group-list {
      .ant-menu-item {
        padding-inline: ${sizes.spm} ${sizes.spm};
      }
    }
  }
`;

export const HeadingFilterCategoryStyleWrapper = styled(Heading)`
  && {
    margin-bottom: ${sizes.spm};
    padding-left: ${sizes.spXl};
  }
`;
