import styled from 'styled-components';

import Heading from 'components/atoms/Heading';
import { media, sizes } from 'styles/variables.module';

export const SearchBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 254px;
  background-image: url('/assets/images/webp/appsColage.webp');
  background-position: right;
  background-color: #c7deff;
  background-repeat: no-repeat;
  border-radius: ${sizes.spm};

  ${media.lg(`
      background-position: 500px top;
  `)}

  ${media.md(`
    background-position: 350px top;
  `)}

  ${media.sm(`
    height: auto;
    background-image: none;
    border-radius: 0;
  `)}
`;

export const HeadingAndSecondTextStyleWrapper = styled.div`
  padding: ${sizes.spXl};
  ${media.sm(`
    padding: ${sizes.spXl} ${sizes.spm};
    width: 75%;
  `)}
`;

export const HeadingStyleWrapper = styled(Heading)`
  && {
    margin-bottom: ${sizes.sps};
  }
`;

export const SearchStyleWrapper = styled.div`
  padding: ${sizes.spXl};
  ${media.sm(`
    padding: 0 ${sizes.spm} ${sizes.sp2xl};
  `)}
`;
