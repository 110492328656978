'use client';

import { FC } from 'react';
import Grid from 'antd/lib/grid';

import SearchInput from 'components/atoms/SearchInput';
import SearchInputWithDropDown from 'components/molecules/SearchInputWithDropDown';
import BodyText from 'components/atoms/BodyText';
import ShowElement from 'components/atoms/ShowElement';
import { IMainSearchBar } from 'components/organisms/MainSearchBar/types';
import useTranslations from 'hooks/common/useTranslations';
import {
  HeadingStyleWrapper,
  SearchStyleWrapper,
  SearchBarWrapper,
  HeadingAndSecondTextStyleWrapper
} from 'components/organisms/MainSearchBar/styles';
import { SearchTypeEnum } from 'components/atoms/SearchInput/types';
import { colors } from 'styles/variables.module';

const { useBreakpoint } = Grid;

const MainSearchBar: FC<IMainSearchBar> = ({
  foundApps,
  onSearchPressEnterHandler,
  searchLoading,
  onChangeSearch,
  onSelectApp,
  onSearchClick
}) => {
  const { xs } = useBreakpoint();
  const { t } = useTranslations('common');

  return (
    <SearchBarWrapper>
      <HeadingAndSecondTextStyleWrapper>
        <HeadingStyleWrapper
          level={1}
          fontWeight="bold"
          color={colors.gray_9}
          text={`${t('ucraft')} ${t('appStore')}`}
        />
        <BodyText
          size="base"
          color={colors.gray_8}
          fontWeight="regular"
          text={t('takeYourStore')}
        />
      </HeadingAndSecondTextStyleWrapper>

      <SearchStyleWrapper>
        <ShowElement
          isShow={Boolean(xs)}
          show={
            <SearchInputWithDropDown
              foundApps={foundApps}
              onSelect={onSelectApp}
              loading={searchLoading}
              onChangeSearch={onChangeSearch}
              onPressEnter={onSearchPressEnterHandler}
            />
          }
        >
          <SearchInput
            onClick={onSearchClick}
            loading={searchLoading}
            searchType={SearchTypeEnum.main}
          />
        </ShowElement>
      </SearchStyleWrapper>
    </SearchBarWrapper>
  );
};

export default MainSearchBar;
