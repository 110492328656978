import BaseApiHandler from 'infrastructure/apiHandlers/baseApiHandler';
import { HeadersEnum } from 'common/globalTypes/enums/http';
import { EndpointCategoriesEnum, IGetCategoriesRequestModel } from './types';
import langConvertByCountryCode from 'utils/helpers/langConvertByCountryCode';
import {
  ICategoriesApiResponseModel,
  ICategoriesApiResponseModelByView
} from 'common/globalTypes/interfaces/apiResponseModel';
import { DEFAULT_LANG } from 'common/constants/common';

class CategoriesApiHandler extends BaseApiHandler {
  constructor() {
    super();
  }

  public async getCategories({
    lang = DEFAULT_LANG
  }: IGetCategoriesRequestModel): Promise<ICategoriesApiResponseModelByView[]> {
    const language = langConvertByCountryCode(lang);
    const categoriesResponse = await this.request.applicationsFetcher.get<
      ICategoriesApiResponseModel[]
    >({
      url: EndpointCategoriesEnum.prefix,
      headerOptions: {
        [HeadersEnum.xCurrentLocale]: language
      },
      next: { revalidate: 300 }
    });

    return categoriesResponse.map(({ id, name, alias }) => ({
      id,
      key: alias,
      label: name
    }));
  }
}

const categoriesApiHandler = new CategoriesApiHandler();

export default categoriesApiHandler;
